.use-top {
  padding-top: 2.3rem !important;
}
.form-element {
  border: 1px solid #b223cc;
  background-color: #f5f5f5;
  padding: 10px;
  margin: 10px 0;
  font-family: "Courier", serif;

  p {
    padding: 0;
    margin: 0;
  }
}

.card-last-change {
  text-transform: none;
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.widget-hover {
  background-color: transparent;

  &:hover {
    background-color: #e0f3ff;
  }
}

.border-money {
  width: 34px;
  height: 34px;
  background: #fff;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  box-shadow: 0 0 0 5px #fff;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  padding-left: 11px;
}

.min-width-25 {
  min-width: 25px;
  display: inline-block;
}

.telegram-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #2aa2de;
  padding: 20px 0 20px;

  &__border {
    width: 84px;
    height: 84px;
    background: #2aa2de;
    border-radius: 50px;
    box-shadow: 0 0 0 5px #fff;
    text-align: center;
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  }
}

.text-alternate {
  color: #593196;
}

.border-alternate {
  border-color: #593196;
}
